import React, { useState } from "react";
import { useEffect } from "react";
import { Link } from "react-router-dom";
import casesJson from "./../components/cases";

const PER_PAGE = 8;

const CasesPage = () => {
  const [cases, setCases] = useState([]);
  const [page, setPage] = useState(1);
  const [totalPage, setTotalPage] = useState(Math.round(casesJson.length / 8));

  const mediaLoaded = (id) => {
    const idx = cases.findIndex((item) => item.id === id);
    setCases((prev) => [
      ...prev.slice(0, idx),
      {
        ...prev[idx],
        loaded: true,
      },
      ...prev.slice(idx + 1),
    ]);
  };

  useEffect(() => {
    setCases(casesJson.slice(0, page * PER_PAGE));
  }, []);

  const moreCases = () => {
    setPage(page + 1);
    setCases((prev) => [
      ...prev,
      ...casesJson.slice(page * PER_PAGE, (page + 1) * PER_PAGE),
    ]);
  };

  return (
    <div className="cases-page">
      <div className="db"></div>

      <header>
        <Link to="/">
          <div className="logo_wrapper">
            <img src="/logo.png" alt="" />
          </div>
        </Link>
      </header>
      <main>
        {/* <ul className="links">
          <li className="link-active">Cases</li>
          <li>
            <Link to="/projects" className="link-nonactive">
              Projects
            </Link>
          </li>
        </ul> */}

        <div className="cases">
          {cases.map((item) => (
            <div className="case" key={item.id} data-id={item.id}>
              <div className="video_outer">
                <div className="video-wrapper">
                  <div className="video-wrapper_inner">
                    <img
                      src={item.low}
                      alt=""
                      className={`${item.loaded && "invisible"}`}
                    />
                  </div>
                  <>
                    {item.video ? (
                      <video
                        src={item.video}
                        preload={"auto"}
                        type={"video/mp4"}
                        autoPlay
                        muted
                        loop
                        onLoadedData={() => mediaLoaded(item.id)}
                      />
                    ) : (
                      <img
                        src={item.poster}
                        onLoad={() => mediaLoaded(item.id)}
                      />
                    )}
                  </>
                </div>
              </div>
              <div className="info">
                <div className="tag">{item.tag}</div>
                <h2>{item.title}</h2>
                <div className="description">{item.about}</div>
              </div>
            </div>
          ))}
        </div>

        {page < totalPage && (
          <button className="more-cases" onClick={moreCases}>
            More cases...
          </button>
        )}
      </main>
    </div>
  );
};

export default CasesPage;
