import './App.css';
import { Routes, Route } from "react-router-dom";

import HomePage from './pages/home';
import CasesPage from './pages/cases';

function App() {
  return (
    <div className="App">
      <Routes>
        <Route path="/" element={<HomePage />} />
        <Route path="cases" element={<CasesPage />} />
      </Routes>
    </div>
  );
}

export default App;
