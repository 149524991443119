import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";

const HomePage = () => {
  const [isLoaded, setIsLoaded] = useState(false);

  useEffect(() => {
    setTimeout(() => {
      setIsLoaded(true);
    }, 2500);
  }, []);

  return (
    <div className="cases-page">
      <div className="db"></div>

      <header>
        <div className="logo_wrapper">
          <img src="/logo.png" alt="" />
        </div>
      </header>

      <main>
        <ul className="list-unstyled lato">
          <li className="list-item">
            <span className="text-grey">We design, develop, launch and support</span>
          </li>
          <li className="list-item">
            <span className="text-grey">websites, online-shops, platforms/services,</span>
          </li>
          <li className="list-item">
            <span className="text-grey">marketplaces and enterprise solutions</span>
          </li>
        </ul>

        <Link to="/cases" className="link">
          Explore all cases
        </Link>

        <div className="socials">
            <a
              href="https://www.linkedin.com/in/vvverbitski/?locale=en_US"
              target="_blank"
              rel="nofollow noopener noreferrer"
            >
              <img src="./linked.svg" alt="" />
            </a>
          </div>

        <div className="domains">
          <div className="domain-list">
            <div>
              <span className="lores">
                <span className="letter_1">W</span>
                <span className="letter_2">e</span>
                <span className="letter_3">b</span>
                <span className="letter_4">3</span>
                <span className="letter_5">/</span>
                <span className="letter_6">B</span>
                <span className="letter_7">l</span>
                <span className="letter_8">o</span>
                <span className="letter_9">c</span>
                <span className="letter_10">k</span>
                <span className="letter_11">h</span>
                <span className="letter_12">a</span>
                <span className="letter_13">i</span>
                <span className="letter_14">n</span>
              </span>
              (Solana, Near, EVM, NFT),
            </div>
            <div>Crypto/Trading,</div>
            <div>Robotics, IoT,</div>
            <div>E-Commerce, SASS,</div>
            <div>Art, Photography, Music,</div>
            <div>Bespoke Retail,</div>
            <div>FinTech, Mobile Apps,</div>
            <div>MedTech</div>
          </div>
        </div>
      </main>
    </div>
  );
};

export default HomePage;
