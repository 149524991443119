const cases = [
  {
    id: 1,
    video: "./videos/prod/cv_x_1.mp4",
    poster: "./videos/prod/cv_x_Moment.jpg",
    low: "./videos/prod/cv_x_Moment_low.jpg",
    title: "Concept of bespoke accessories brand",
    tag: "PROMO WEBSITE",
    about: "",
  },
  {
    id: 2,
    video: "./videos/prod/behati_x_1.mp4",
    poster: "./videos/prod/behati_x_Moment.jpg",
    low: "./videos/prod/behati_x_Moment_low.jpg",
    title: "Landing Page for a Contemporary Artist",
    tag: "PROMO WEBSITE",
    about: "I designed and developed a Brand Website for a fine art artist",
  },
  {
    id: 3,
    poster: "./videos/prod/red_x.png",
    low: "./videos/prod/red_x_low.jpg",
    title: "Website Design and UI for Digital Agency",
    tag: "Brand website",
    about: "I designed a website for a digital agency",
  },
  {
    id: 4,
    video: "./videos/prod/agent_x_1.mp4",
    poster: "./videos/prod/agent_x_Moment.png",
    low: "./videos/prod/agent_x_Moment_low.jpg",
    title: "Gestalt Landing Page for a Real Estate Agent",
    tag: "Promo website",
    about: "I developed a high-profitable landing page for a real estate agent",
  },
  {
    id: 5,
    video: "./videos/prod/betworld_x_1.mp4",
    poster: "./videos/prod/betworld_x_Moment.png",
    low: "./videos/prod/betworld_x_Moment_low.jpg",
    title: "Premium Website for Forecasts Agency",
    tag: "Platform",
    about:
      "I designed and developed a web-platform for forecast's company with subscriptions, recuring payments, blog and billing",
  },
  {
    id: 6,
    video: "./videos/prod/cri_1.mp4",
    poster: "./videos/prod/cri_Moment.png",
    low: "./videos/prod/cri_Moment_low.jpg",
    title: "Personal Promo Website for photographer Christina Verber",
    tag: "Brand website",
    about:
      "I designed and developed a multilocal website with Content Managing System based on WordPress for a photographer",
  },
  {
    id: 7,
    video: "./videos/prod/elton_x_1.mp4",
    poster: "./videos/prod/elton_x_Moment.png",
    low: "./videos/prod/elton_x_Moment_low.jpg",
    title: "Personal Website for Musician Crying Twins",
    tag: "Brand website",
    about:
      "I designed and developed a minimal website with Content Managing System for musician",
  },
  {
    id: 8,
    video: "./videos/prod/candyshop_x_1.mp4",
    poster: "./videos/prod/candyshop_x_Moment.png",
    low: "./videos/prod/candyshop_x_Moment_low.jpg",
    title: "Online Sex Shop Candyshop.by",
    tag: "Online Shop",
    about:
      "I prototyped, designed and developed an online sexshop for 15 000 adult products",
  },
  {
    id: 9,
    poster: "./videos/prod/ex_x.png",
    low: "./videos/prod/ex_x_low.jpg",
    title: "Extenders Online Shop Extender.by",
    tag: "Online Shop",
    about: "I designed and developed an online store for male extenders",
  },
  {
    id: 10,
    video: "./videos/prod/orgazm_x_1.mp4",
    poster: "./videos/prod/orgazm_x_Moment.jpg",
    low: "./videos/prod/orgazm_x_Moment_low.jpg",
    title: "Mobile Version for Online Shop Orgazm.by",
    tag: "Mobile development",
    about:
      "I redesigned and developed a mobile version for an online sexshop on OpenCart",
  },
  {
    id: 11,
    video: "./videos/prod/hashio_x_1.mp4",
    poster: "./videos/prod/hashio_x_Moment.jpg",
    low: "./videos/prod/hashio_x_Moment_low.jpg",
    title: "Fintech Checkout Website - Hashio.io",
    tag: "Enterprise",
    about:
      "I designed and developed a front website for customers service and exposure for mining company",
  },
  {
    id: 12,
    video: "./videos/prod/btc_x_1.mp4",
    poster: "./videos/prod/btc_x_Moment.jpg",
    low: "./videos/prod/btc_x_Moment_low.jpg",
    title: "Crypto Investment Platform BTC-FOUNDER.com",
    tag: "Platform",
    about:
      "I designed and developed an investment crypto platform with managing modules and billing",
  },
  {
    id: 13,
    video: "./videos/prod/daiberi_x_1.mp4",
    poster: "./videos/prod/daiberi_x_Moment.jpg",
    low: "./videos/prod/daiberi_x_Moment_low.jpg",
    title: "Social Platform for Artists & Media Workers Daiberi.com",
    tag: "Marketplace",
    about:
      "I designed and developed an MVP for artist's and Art Industry social platform - Daiberi.com",
  },
  {
    id: 14,
    video: "./videos/prod/fetish_x_1.mp4",
    poster: "./videos/prod/fetish_x_Moment.jpg",
    low: "./videos/prod/fetish_x_Moment_low.jpg",
    title: "BDSM & Fetish Marketplace - Fetish-x.com",
    tag: "Marketplace",
    about:
      "I prototyped, designed and developed a SexTech Startup - BDSM and Fetish Marketplace",
  },
  {
    id: 15,
    video: "./videos/prod/chastity_x_1_1.mp4",
    poster: "./videos/prod/chastity_x_Moment.jpg",
    low: "./videos/prod/chastity_x_Moment_low.jpg",
    title: "Web Application Chastity Farm",
    tag: "Platform",
    about:
      "I designed and developed a customer tracker and managing system application - sextech",
  },
  {
    id: 16,
    video: "./videos/prod/crypto_x_1.mp4",
    poster: "./videos/prod/crypto_x_Moment.jpg",
    low: "./videos/prod/crypto_x_Moment_low.jpg",
    title:
      "Cryptotrading Platform for Signals Tracking and Monitoring Cryptogava.com",
    tag: "Platform",
    about:
      "I designed and developed a huge MVP and a cryptostock simulator for cryptotraders and trading signals tracking",
  },
  {
    id: 17,
    video: "./videos/prod/capper_x_1.mp4",
    poster: "./videos/prod/capper_x_Moment.jpg",
    low: "./videos/prod/capper_x_Moment_low.jpg",
    title: "SPA for Betting Managing System by Martingale",
    tag: "Enterprise",
    about:
      "I prototyped, designed and developed a realtime application for sport forecasts and betting managing by Martingale's System with feed, stats, chat, notifications, etc.",
  },
  {
    id: 18,
    video: "./videos/prod/self_x_1.mp4",
    poster: "./videos/prod/self_x_Moment.jpg",
    low: "./videos/prod/self_x_Moment_low.jpg",
    title: "Website Portfolio for myself",
    tag: "Promo website",
    about:
      "I designed and developed several times a beautiful front website and content managing system for myself",
  },
  {
    id: 19,
    video: "./videos/prod/wumblr_x_1.mp4",
    poster: "./videos/prod/wumblr_x_Moment.jpg",
    low: "./videos/prod/wumblr_x_Moment_low.jpg",
    title: "Blog Platform - Wumblr.com",
    tag: "Platform",
    about:
      "I designed and developed an uncensored blogging tumblr-alike platform",
  },
  {
    id: 20,
    video: "./videos/prod/omm_x_1.mp4",
    poster: "./videos/prod/omm_x_Moment.jpg",
    low: "./videos/prod/omm_x_Moment_low.jpg",
    title: "Control Panel for Music Projects - Omm.fm",
    tag: "Platform",
    about:
      "I built prototype, designed and developed a music industry startup - a single platform for music projects",
  },
  {
    id: 21,
    video: "./videos/prod/tusa_x_1.mp4",
    poster: "./videos/prod/tusa_x_Moment.jpg",
    low: "./videos/prod/tusa_x_Moment_low.jpg",
    title: "Business Platform - Tusa.cc",
    tag: "Platform",
    about:
      "I parsed the data, designed and developed a business platform with boards, classifieds and forum",
  },
  {
    id: 22,
    video: "./videos/prod/kvint_x_1.mp4",
    poster: "./videos/prod/kvint_x_Moment.jpg",
    low: "./videos/prod/kvint_x_Moment_low.jpg",
    title: "Fetish Art Online Shop for Kvint Kallas",
    tag: "Online Shop",
    about:
      "I designed and developed the sexiest and elegant website with online shop, blog and other specific custom parts for a fetish artist",
  },
  {
    id: 23,
    video: "./videos/prod/apache_x_1.mp4",
    poster: "./videos/prod/apache_x_Moment.jpg",
    low: "./videos/prod/apache_x_Moment_low.jpg",
    title: "Sport Online Shop - Apache.by",
    tag: "Online Shop",
    about: "I designed and developed a stylish Bikes Online Shop",
  },
  {
    id: 24,
    video: "./videos/prod/dostovka_1_x_1.mp4",
    poster: "./videos/prod/dostovka_1_x_Moment.jpg",
    low: "./videos/prod/dostovka_1_x_Moment_low.jpg",
    title: "Delivery Service - Dostovka.com",
    tag: "Service",
    about:
      "I prototyped, designed and developed an MVC for grocery devivering startup in extremly hard terms - in 3 days",
  },
  {
    id: 25,
    video: "./videos/prod/abazur_x_1.mp4",
    poster: "./videos/prod/abazur_x_Moment.jpg",
    low: "./videos/prod/dostovka_1_x_Moment_low.jpg",
    title: "Children's Books Online Shop - Abazhurchik.by",
    tag: "Online Shop",
    about:
      "I designed and developed an online store with 2 500 products for a children's books company",
  },
  {
    id: 26,
    video: "./videos/prod/dostovka_x_1.mp4",
    poster: "./videos/prod/dostovka_x_Moment.jpg",
    low: "./videos/prod/dostovka_x_Moment_low.jpg",
    title: "Grocery Delivery Service Dostovka.com",
    tag: "Service",
    about:
      "I prototyped, designed, developed and launched a grocery delivery service with 165 000 products - startup",
  },
  {
    id: 27,
    video: "./videos/prod/magazino_x_1.mp4",
    poster: "./videos/prod/magazino_x_Moment.jpg",
    low: "./videos/prod/magazino_x_Moment_low.jpg",
    title: "Mobile Application Magazino.io",
    tag: "Mobile Application",
    about:
      "I designed and developed a mobile application for the grocery delivery service",
  },
  {
    id: 28,
    video: "./videos/prod/vvvvvv_x_1.mp4",
    poster: "./videos/prod/vvvvvv_x_Moment.jpg",
    low: "./videos/prod/vvvvvv_x_Moment_low.jpg",
    title: "Website and Managing Panel for Digital Agency V6V",
    tag: "promo website",
    about:
      "I prototyped a structure, designed and developed a website for Digital Agency. Than restyled it and relaunched due to web-analytics reasons",
  },
  {
    id: 29,
    video: "./videos/prod/99bet_x_1.mp4",
    poster: "./videos/prod/99bet_x_Moment.jpg",
    low: "./videos/prod/99bet_x_Moment_low.jpg",
    title: "Sport agency & forecasts website 99Bet.site",
    tag: "promo website",
    about:
      "I prototyped UX/UI, designed and developed a sport agency's forecast platform",
  },
  {
    id: 30,
    video: "./videos/prod/master_x_1.mp4",
    poster: "./videos/prod/master_x_Moment.jpg",
    low: "./videos/prod/master_x_Moment_low.jpg",
    title: "Landing Page for eCommerce Master Class",
    tag: "promo website",
    about:
      "I did copywriting, designed and developed a profitable landing page for e-Commerce masterclass",
  },
  {
    id: 31,
    video: "./videos/prod/grandex_x_1.mp4",
    poster: "./videos/prod/grandex_x_Moment.jpg",
    low: "./videos/prod/grandex_x_Moment_low.jpg",
    title: "Online Currency Exchange GrandEx.io",
    tag: "platform",
    about:
      "I designed and developed an online currency exchange platform for cryptocurrencies and fiat from the scratch",
  },
  {
    id: 32,
    video: "./videos/prod/cleaning_x_1.mp4",
    poster: "./videos/prod/cleaning_x.jpg",
    low: "./videos/prod/cleaning_x_low.jpg",
    title: "Cleaning Service in San Diego, California",
    tag: "promo website",
    about:
      "I designed and developed bright and candy website for a cleaning company with convenient management system",
  },
  {
    id: 33,
    poster: "./videos/prod/icol_moment.jpg",
    low: "./videos/prod/icol_moment_low.jpg",
    video: "./videos/prod/icol.mp4",
    title: "Control application for Robotics Factory",
    tag: "Robotics, IoT",
    about:
      "I designed and developed a web-based application for controling footwear smart factory by ICOL.com",
  },
  {
    id: 34,
    poster: "./videos/prod/communna_moment.jpg",
    low: "./videos/prod/communna_moment_low.jpg",
    video: "./videos/prod/communna.mp4",
    title: "Social Game Concept",
    tag: "Game Development",
    about:
      "I designed a social game concept for iOS based on Clubhouse mechanics",
  },
  {
    id: 35,
    poster: "./videos/prod/environet_moment.jpg",
    low: "./videos/prod/environet_moment_low.jpg",
    video: "./videos/prod/environet.mp4",
    title: "IoT Monitoring Panel for Environet",
    tag: "IoT",
    about:
      "I designed a concept and took a part in frontend development of monitoring platform",
  },
  {
    id: 36,
    poster: "./videos/prod/pawnshop_moment.jpg",
    low: "./videos/prod/pawnshop_moment_low.jpg",
    video: "./videos/prod/pawnshop.mp4",
    title: "Blockchain NFT Pawnshop on Solana",
    tag: "Blockchain/Web3",
    about:
      "I developed a Solana Program on Rust and Web3 application for Pawnshop Gnomies - TOP-3 projects on Solana",
  },
  {
    id: 37,
    poster: "./videos/prod/upfinity_moment.jpg",
    low: "./videos/prod/upfinity_moment_low.jpg",
    video: "./videos/prod/upfinity.mp4",
    title: "Complex AI Application for Upfinity.io",
    tag: "Blockchain/Web3",
    about:
      "I developed and designed simple and smooth UX/UI for a complex product based on AI",
  },
  {
    id: 38,
    poster: "./videos/prod/nftpapa_moment.jpg",
    low: "./videos/prod/nftpapa_moment.jpg",
    video: "./videos/prod/nftpapa.mp4",
    title: "NFT Wallets Explorer on NEAR NFTPAPA.XYZ",
    tag: "Blockchain/Web3",
    about:
      "I created a Web3 application that allows to check what NFTs belongs to a specific wallet on NEAR",
  },
  {
    id: 39,
    poster: "./videos/prod/pionears_moment.jpg",
    low: "./videos/prod/pionears_moment_low.jpg",
    video: "./videos/prod/pionears.mp4",
    title: "NFT Staking Application on NEAR",
    tag: "Blockchain/Web3",
    about:
      "I coded an NFT collection ThePionears and developed a staking contract on NEAR with Rust for the collection",
  },
  {
    id: 40,
    poster: "./videos/prod/3cast_moment.jpg",
    low: "./videos/prod/3cast_moment_low.jpg",
    video: "./videos/prod/3cast.mp4",
    title: "Listen2Earn Application on Solana - 3Cast.org",
    tag: "Blockchain/Web3",
    about:
      "I coded Solana Program on Rust, developed the backend on Node and iOS app on React Native",
  },
  {
    id: 41,
    poster: "./videos/prod/paper_low.jpg",
    low: "./videos/prod/paper.jpg",
    video: "./videos/prod/paper.mp4",
    title: "NFT Perpetuals Exchange perpify.xyz",
    tag: "Blockchain/Web3",
    about:
      "I coded EVM smart contracts based on perp fork and Uniswap, designed, developed the backend on Node and Front end application",
  },
];

export default cases.sort((a, b) => b.id - a.id);
